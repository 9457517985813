import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
import { ProductDetail } from "../components/detail"
import { Vignette } from "../components/vignette"
import { Avantages, Spacer, Newsletter } from "../components/banners"
import Insta from "../components/insta"

const H1 = ({ children }) => (
  <h1
    style={{
      fontSize: "1.6rem",
      textAlign: "center",
      letterSpacing: "0.15rem",
      fontWeight: 'bold'
    }}
  >
    {children}
  </h1>
)

const H2 = ({ children }) => (
  <h2
    style={{ fontSize: "1.6rem", letterSpacing: "0.15rem", fontWeight: "bold" }}
  >
    {children}
  </h2>
)

const P = ({ children }) => (
  <p style={{ letterSpacing: "0.15rem", maxWidth: "700px" }}>{children}</p>
)

export default () => (
  <Layout>
    <Spacer v="5rem"></Spacer>
    <H1>Qui sommes-nous ?</H1>
    <H2>vous.</H2>

    <P>
      On ne parlera pas ici de notre histoire, de notre inspiration ou de notre
      petit-déjeuner. Ce qui compte ici c’est vous ! Votre histoire, votre
      légèreté, votre joie et tout ce qui vous rend si extraordinairement vous.
    </P>

    <H2>La dictée c’est fini !</H2>

    <P>
      Marianne By Marie Jordane ne cherche pas à vous dicter un style, une
      allure ou une façon de vivre. Pas de fashion statement, pas de jugement.
      Car nous pensons que c’est dans la liberté d’être vous que vous êtes la
      plus rayonnante. Nos vêtements sont des essentiels de personnalité. Ils
      prennent vie entre vos mains. Que vous soyez fashionista, bohème, boyish,
      preppy ou rien de tout ça : façonnez-les, sublimez-les, détournez-les,
      vivez !
    </P>

    <H2>C’est pas sorcier.</H2>

    <P>
      Chez nous, tout est question d’équilibre et de plaisir. Nous avons donc
      tout simplement choisi de faire les choses sainement : production locale
      et matières naturelles. Pour que vous puissiez porter nos vêtements les
      yeux fermés et le coeur léger. Avec la certitude de n’avoir profité ni de
      la planète, ni de personne !
    </P>

    <H2>Look good, do good, feel good !</H2>

    <P>
      Notre philosophie en quelques mots ! Nous voulons une mode qui booste
      l’esprit. Des vêtements qui soient source d’une affirmation épanouie et
      sereine de vous. Bref une mode pour être belle et bien : avec soi, avec
      les autres et avec la planète !
    </P>

    <p style={{textAlign: 'center', textDecoration: 'italic', fontWeight: 'bold', 
    letterSpacing: '0.15rem', margin: '5rem 0'}}>
    A toutes les femmes, amies, soeurs, amoureuses, working girls, start-upeuses et<br />
netflixeuses professionnelles,<br/>
We love you more than croissants,<br />
Marianne by Marie Jordane.
    </p>

    <H1>Remerciements</H1>
    <p style={{textAlign: 'center', fontSize: '1.2rem', 
    letterSpacing: '0.15rem', margin: '3rem 0'}}>
    Parce que même si on est trop fortes, qu’on carbure au café et qu’on est objectivement très efficaces, nous tenons à remercier ceux sans qui cette belle aventure n’aurait pas été possible :
    </p>

    <p
    >Aurélie, notre première stagiaire modéliste qui a commencé avec nous le premier jour de notre installation à l’atelier. Le premier jour de température hivernale également on s’en souvient, on avait pas le chauffage !<br />
Albina, notre seconde stagiaire grâce à qui on a réalisé qu’on peut faire vachement dans une journée de travail quand on prend pas de pause clope (cette fille est une machine !)<br />
Philippe, qui nous a fait un logo de tueuses.<br />
Et tous nos proches pour leur soutien inconditionnel<br />
<br />
On vous aime aussi très fort. (Larme)<br />
</p>
<Spacer v="15rem" />
<Avantages />
<Spacer v="6rem"></Spacer>

<Newsletter />
<Spacer v="5rem" />
<Insta />

  </Layout>
)
